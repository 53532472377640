import React, { useEffect } from "react";
import AnalyticsEventTracker from "./AnalyticsEventTracker";
import Header from "./Header";

const ThankYou = () => {
  const gaEventTracker = AnalyticsEventTracker("Payment Successfull Page");

  useEffect(() => {
    gaEventTracker("Payment Successful");
  }, []);

  return (
    <div className="epione">
      <div className="epi_container">
        <Header />
        <div className="img_container first_visit">
          <div className="epi_page_title">
            Thank you for scheduling <br />
            an appointment. Please expect a call from us within the next 24-48
            hours. We look forward to seeing you soon!
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
