import React from "react";
import logo from "./../images/logo.png";
import { Link } from "react-router-dom";

const Header = ({ url }) => {
  return (
    <>
      <div className="logo">
        {url ? (
          <Link to={url} className="epi_back_btn">
            <i
              className="fa fa-arrow-left"
              style={{ fontSize: 16 }}
              aria-hidden="true"
            ></i>
            <span
              style={{ paddingLeft: 5, display: "inline-block", fontSize: 12 }}
            >
              {" "}
              Back
            </span>
          </Link>
        ) : (
          ""
        )}
        <div className="text-center">
          <img src={logo} alt="Epione" />
        </div>
      </div>
    </>
  );
};

export default Header;
