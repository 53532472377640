import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AnalyticsEventTracker from "./AnalyticsEventTracker";
import api from "./api";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import Footer from "./Footer";

const StepSix = () => {
  const [date, setDate] = useState();
  const [info, setInfo] = useState([]);
  const [today, setToday] = useState(new Date());
  const [active, setActive] = useState(false);
  const [enabledDates, setEnabledDates] = useState();
  const Navigate = useNavigate();

  useEffect(() => {
    getEnabledDates();
  }, []);

  const gaEventTracker = AnalyticsEventTracker("Select Appointment Date");

  const getEnabledDates = async () => {
    const epi_data = sessionStorage.getItem("info");
    if (epi_data) {
      setInfo(JSON.parse(epi_data));
      var session = "";
      if (JSON.parse(epi_data).sessionId)
        session = JSON.parse(epi_data).sessionId;
      const resp = await api.post("availability.php", { session_id: session });
      const epi_info = { ...JSON.parse(epi_data) };
      epi_info.sessionId = resp.data.session_id;
      setInfo({ ...epi_info });
      const result = resp.data.data.map((obj) => Object.keys(obj)[0]);
      if (JSON.parse(epi_data).order_id && !result.includes(info.date)) {
        result.push(JSON.parse(epi_data).date);
      }
      if (JSON.parse(epi_data).order_id && !result.includes(info.date)) {
        result.push(JSON.parse(epi_data).date);
      }
      setEnabledDates(result);
      setActive(true);
    } else Navigate("/");
  };

  const setpChange = (value) => {
    gaEventTracker("Date Selected");
    const selectDate = new Date(value);
    const selected =
      selectDate.getFullYear() +
      "-" +
      selectDate.toLocaleString("en-US", { month: "2-digit" }) +
      "-" +
      String(selectDate.getDate()).padStart(2, "0");
    if (!enabledDates.includes(selected)) {
      Swal.fire({
        text: "Sorry, please select another date",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return true;
    } else {
      const currentDate = new Date(value);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      if (info.date && info.date != formattedDate) delete info.time_slot;
      info.dateString = value;
      info.date = formattedDate;
      if (!info.order_id) delete info.time_slot;
      setInfo({ ...info });
      sessionStorage.setItem("info", JSON.stringify(info));
      Navigate("/available-slots");
    }
  };

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 1);
  };

  const tileDisabled = ({ date }) => {
    const formattedDate = formatDate(date);
    const currentDate = formatDate(new Date());
    return (
      currentDate > formattedDate || // Disable past dates
      !enabledDates.some((enabledDate) => enabledDate === formattedDate) // Disable dates not in enabledDates array
    );
  };

  const isDateDisabled = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const getTileClassName = ({ date }) => {
    const formattedDate = formatDate(date);
    const currentDate = formatDate(new Date());
    if (
      !enabledDates.includes(formattedDate) &&
      formattedDate >= currentDate &&
      date.getDay() != 0 &&
      date.getDay() != 6
    ) {
      return "disabled-date epi_date";
    }
    return "epi_date";
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {active ? (
        <div className="epione">
          <div className="epi_container">
            <Header url="/consult-prefer" />
            <div className="calender_div">
              <div className="epi_page_title mb-3">Select a Date & Time</div>
            </div>
            <div>
              <Calendar
                className="epione_calendar"
                calendarType="US"
                onClickDay={(value) => setpChange(value)}
                value={info.dateString ? info.dateString : date}
                showNeighboringMonth={false}
                formatShortWeekday={formatShortWeekday}
                //tileDisabled={tileDisabled}
                tileDisabled={({ date }) => isDateDisabled(date)}
                tileClassName={getTileClassName}
                minDate={today}
                maxDate={
                  new Date(
                    today.getFullYear(),
                    today.getMonth() + 6,
                    today.getDate()
                  )
                }
                view="month"
                showYearDropdown={false}
                showYearPicker={false}
              />
            </div>
            <div
              className="mt-3 slots_block"
              style={{ display: "flex", paddingTop: 25 }}
            >
              <div
                className="slots_block_div"
                style={{ display: "flex", marginRight: 15 }}
              >
                <span
                  className="epi_ball"
                  data-tooltip-id="a_slots"
                  data-tooltip-content="Slots Available"
                  style={{ backgroundColor: "#1976d2" }}
                ></span>{" "}
                <b>Slots Available</b>
                <Tooltip id="a_slots" />
              </div>
              <div
                className="slots_block_div"
                style={{ display: "flex", marginRight: 15 }}
              >
                <span
                  className="epi_ball"
                  style={{ backgroundColor: "#e0e0e0" }}
                  data-tooltip-id="no_slots"
                  data-tooltip-content="No Slots Available"
                ></span>{" "}
                <b>No Slots Available</b>
              </div>
              <Tooltip id="no_slots" />
            </div>
            <Footer />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StepSix;
