import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import AnalyticsEventTracker from "./AnalyticsEventTracker";
import Footer from "./Footer";
const StepFive = () => {
  const [info, setInfo] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    const data = sessionStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
    else Navigate("/");
  }, []);

  const gaEventTracker = AnalyticsEventTracker("Consult Prefer");

  const setpChange = (value) => {
    gaEventTracker(value);
    info.consult = value;
    setInfo({ ...info });
    sessionStorage.setItem("info", JSON.stringify(info));
    Navigate("/select-date");
  };

  return (
    <div className="epione">
      <div className="epi_container">
        <Header url="/visit-info" />
        <div className="img_container first_visit">
          <div className="epi_page_title">
            How would you prefer to <br />
            consult with us?
          </div>
        </div>
        <div className="epi_btn_sec yes_no">
          <div
            className={
              info.consult === "In-Person"
                ? "epi_btn mb-3 epi_btn_active"
                : "epi_btn mb-3"
            }
            onClick={() => setpChange("In-Person")}
          >
            In-Person
          </div>
          <a
            href="https://virtualmdus.com/epione?source=ZXBpb25lX2NvbnN1bHRhdGlvbg=="
            className={
              info.consult === "Online"
                ? "epi_btn mb-3 epi_btn_active"
                : "epi_btn mb-3"
            }
          >
            Online
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default StepFive;
