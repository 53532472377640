import React from "react";
import ReactGA from "react-ga";
const AnalyticsEventTracker = (category = "Category") => {
  const eventTracker = (action = "Epione Appointments", label = "Label") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};

export default AnalyticsEventTracker;
