import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsEventTracker from "./AnalyticsEventTracker";
import Header from "./Header";

const ConcernThankYou = () => {
  const [info, setInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const Navigate = useNavigate();

  const gaEventTracker = AnalyticsEventTracker("Concern Thankyou page");

  useEffect(() => {
    gaEventTracker("thankyou");
    const data = sessionStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
  }, []);

  return (
    <div className="epione">
      <div className="epi_container">
        <Header />
        <div className="img_container great_to">
          <div className="epi_page_title">
            Thank you. <br />
            Please expect a call or email
            <br /> from us soon.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcernThankYou;
