import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "./Header";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import AnalyticsEventTracker from "./AnalyticsEventTracker";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import Footer from "./Footer";

const CustomCountrySelect = ({ value, onChange }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState("US");
  const handleChange = (event) => {
    onChange(event.target.value);
    setSelected(event.target.value);
  };

  useEffect(() => {
    var customCountryList = [
      ...getCountries().filter((country) => country !== "US"),
    ];
    const customCountriesData = [];
    customCountryList.map((country) =>
      customCountriesData.push({
        label: en[country],
        value: country,
        code: getCountryCallingCode(country),
        name: en[country],
      })
    );
    var result = customCountriesData.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const newValue = {
      label: "United States",
      name: "United States",
      code: "1",
      value: "US",
    };
    setItems([newValue, ...result]);
  }, []);

  return (
    <>
      <div className="PhoneInputCountry">
        <select
          className="PhoneInputCountrySelect"
          value={value}
          onChange={handleChange}
        >
          {items.map((country) => (
            <option key={country.value} value={country.value}>
              {country.label}
            </option>
          ))}
        </select>
        <div
          aria-hidden="true"
          className="PhoneInputCountryIcon PhoneInputCountryIcon--border"
        >
          <img
            className="PhoneInputCountryIconImg"
            alt={selected}
            src={
              "https://purecatamphetamine.github.io/country-flag-icons/3x2/" +
              selected +
              ".svg"
            }
          />
        </div>
        <div className="PhoneInputCountrySelectArrow"></div>
        {/* <span className={"fi fi-" + selected.toLowerCase()}></span> */}
      </div>
    </>
  );
};

const StepThree = () => {
  const [info, setInfo] = useState({
    phone: "",
    email: "",
    terms_conditions: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const data = sessionStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
    else navigate("/");
  }, []);

  const gaEventTracker = AnalyticsEventTracker("Contact Details");

  const stepChange = () => {
    setSubmitted(true);
    if (info.phone && info.email && info.terms_conditions) {
      gaEventTracker("Info");
      sessionStorage.setItem("info", JSON.stringify(info));
      navigate("/visit-info");
    }
  };

  const chnageHandler = (checked, value) => {
    if (checked) setInfo({ ...info, terms_conditions: value });
    else setInfo({ ...info, terms_conditions: "" });
  };

  return (
    <div className="epione">
      <div className="epi_container">
        <Header url="/about" />
        <div className="img_container great_to">
          <div className="epi_page_title">
            Please fill in your phone number <br /> and email id
          </div>
        </div>
        <div className="epi_fields">
          <div className="mb-3">
            <PhoneInput
              className="custom-phone-input"
              placeholder="Phone (XXX) XXX XXXX"
              defaultCountry="US"
              countrySelectComponent={CustomCountrySelect}
              value={info.phone}
              onChange={(phone) => setInfo({ ...info, phone })}
              style={{
                borderBottomColor: submitted && !info.phone ? "red" : "#ccc",
              }}
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              placeholder="E-mail"
              className="epi_input"
              onChange={(e) => setInfo({ ...info, email: e.target.value })}
              value={info.email}
              style={{
                borderBottomColor: submitted && !info.email ? "red" : "#ccc",
              }}
            />
          </div>
          <div className="mb-3">
            <div class="form-group">
              <div
                style={{
                  position: "relative",
                  color: submitted && !info.terms_conditions ? "red" : "#000",
                }}
              >
                <input
                  type="checkbox"
                  id="terms"
                  checked={info.terms_conditions == "yes" ? true : false}
                  value="yes"
                  onChange={(e) =>
                    chnageHandler(e.target.checked, e.target.value)
                  }
                  style={{
                    position: "absolute",
                    top: 6,
                    borderColor:
                      submitted && !info.terms_conditions ? "red" : "#000",
                  }}
                />
                <label for="terms" style={{ paddingLeft: 20 }}>
                  I consent to receive news, updates, promotions, and marketing
                  offers via e-mail and SMS from Epione Medical Corporation and
                  Dr. Simon Ourian.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="epi_btn_sec">
          <div className="epi_btn" onClick={stepChange}>
            Next
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default StepThree;
