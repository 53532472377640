import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Swal from "sweetalert2";

import Welcome from "./components/Welcome";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import StepFive from "./components/StepFive";
import StepSix from "./components/StepSix";
import StepSeven from "./components/StepSeven";
import StripePaymentForm from "./components/StripePaymentForm";
import ThankYou from "./components/ThankYou";
import YourConcern from "./components/YourConcern";
import ConcernThankYou from "./components/ConcernThankYou";

const App = () => {
  useEffect(() => {
    const TRACKING_ID = "G-YJN836PJD2 ";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.onvisibilitychange = function () {
      if (document.visibilityState == "hidden")
        sessionStorage.removeItem("info");
    };
    const handleBeforeUnload = (ev) => {
      ev.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/:data" element={<Welcome />} />
        <Route path="/about" element={<StepTwo />} />
        <Route path="/contact-details" element={<StepThree />} />
        <Route path="/visit-info" element={<StepFour />} />
        <Route path="/describe-your-concern" element={<YourConcern />} />
        <Route path="/consult-prefer" element={<StepFive />} />
        <Route path="/select-date" element={<StepSix />} />
        <Route path="/available-slots" element={<StepSeven />} />
        {/* <Route path="/card-details" element={<StepEight />} /> */}
        <Route path="/card-details" element={<StripePaymentForm />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/thankyou" element={<ConcernThankYou />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
