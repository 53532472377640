import React from "react";

const Footer = () => {
  return (
    <div className="mb-3 mt-3 text-center" style={{ margin: 12 }}>
      <div>Need help? Email us at booknow@epione.com</div>
    </div>
  );
};

export default Footer;
