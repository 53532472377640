import React, { useState, useEffect } from "react";
import Header from "./Header";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import AnalyticsEventTracker from "./AnalyticsEventTracker";
import api from "./api";
import { useNavigate, useHistory } from "react-router-dom";
import Footer from "./Footer";

// const stripePromise = loadStripe(
//   "pk_test_51NEUUuLMvHpacMIxEp3ZNzYaMNjRUWYZdofa0wtfxbhdBnuyellhGtgtGkVUE2T6exCYZOtMqyR2VO6pIDpMetuM00D28HrAYV"
// );

const PaymentForm = ({ info }) => {
  const stripe = useStripe();
  const elements = useElements();
  const Navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const gaEventTracker = AnalyticsEventTracker("Card Details");

  const handleSubmit = (stripe, elements) => async () => {
    setSubmitted(true);
    gaEventTracker("Payment Button");
    var data_new = JSON.parse(sessionStorage.getItem("info"));
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setSubmitted(false);
      Swal.fire({
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
      return true;
    } else {
      const params = {
        payment: paymentMethod,
        id: info.order_id,
        info: info,
      };
      const resp = await api.post("save_payment.php", params);
      console.log(resp.data);
      if (resp.data.success == true) {
        sessionStorage.removeItem("info");
        Navigate("/thank-you");
      } else if (resp.data.success == false) {
        Swal.fire({
          text: resp.data.error_message,
          icon: "error",
          confirmButtonText: "OK",
        });
        setSubmitted(false);
      } else setSubmitted(false);
    }
  };

  return (
    <>
      <CardElement />
      <button
        className="epi_btn"
        style={{
          border: "none",
          margin: "25px auto",
          cursor: submitted ? "no-drop" : "pointer",
        }}
        onClick={handleSubmit(stripe, elements)}
        disabled={submitted}
      >
        {submitted ? "Submitting..." : "Submit"}
      </button>
    </>
  );
};

const StripePaymentForm = () => {
  const [info, setInfo] = useState([]);
  const [stripePromise, setStripePromise] = useState("");
  const [active, setActive] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    getPaymentSettings();
  }, []);

  const getPaymentSettings = async () => {
    const data = sessionStorage.getItem("info");
    if (data) {
      setInfo(JSON.parse(data));
      const resp = await api.get("api.php?action=get-payment-settings");
      setStripePromise(loadStripe(resp.data.publish_key));
      setActive(true);
    } else Navigate("/");
  };

  return (
    <>
      {active ? (
        <div className="epione">
          <div className="epi_container">
            <Header url="/available-slots" />
            <div className="mb-3" style={{ padding: 15 }}>
              To secure your chosen slot, we would be needing your payment
              information. You would not get charged unless you're in. You may
              cancel up to 24 hours before your consultation schedule, free of
              charge
            </div>
            <div style={{ maxWidth: 400, width: "100%", marginTop: 25 }}>
              <Elements stripe={stripePromise}>
                <PaymentForm info={info} />
              </Elements>
            </div>
            <Footer />
          </div>
        </div>
      ) : null}
    </>
  );
};
export default StripePaymentForm;
