import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AnalyticsEventTracker from "./AnalyticsEventTracker";

import Header from "./Header";
import Footer from "./Footer";
const StepTwo = () => {
  const [info, setInfo] = useState({ first_name: "", last_name: "" });
  const [submitted, setSubmitted] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    const data = sessionStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
  }, []);

  const gaEventTracker = AnalyticsEventTracker("About");

  const stepChange = () => {
    setSubmitted(true);
    if (info.first_name && info.last_name) {
      gaEventTracker("Next");
      sessionStorage.setItem("info", JSON.stringify(info));
      Navigate("/contact-details");
    }
  };
  return (
    <div className="epione">
      <div className="epi_container">
        <Header url="/" />
        <div className="img_container great_to">
          <div className="epi_page_title">
            {/* It would be great to hear <br />
            from you. Please fill out the form <br />
            below to contact us. */}
            Please fill out the form <br /> with your details:
          </div>
        </div>
        <div className="epi_fields">
          <div className="mb-3">
            <input
              type="text"
              placeholder="First Name"
              onChange={(e) => setInfo({ ...info, first_name: e.target.value })}
              className="epi_input"
              value={info.first_name}
              name="first_name"
              style={{
                borderBottomColor:
                  submitted && !info.first_name ? "red" : "#ccc",
              }}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Last Name"
              onChange={(e) => setInfo({ ...info, last_name: e.target.value })}
              value={info.last_name}
              name="last_name"
              className="epi_input"
              style={{
                borderBottomColor:
                  submitted && !info.last_name ? "red" : "#ccc",
              }}
            />
          </div>
        </div>
        <div className="epi_btn_sec">
          <div className="epi_btn" onClick={stepChange}>
            Next
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default StepTwo;
