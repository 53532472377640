import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsEventTracker from "./AnalyticsEventTracker";
import Header from "./Header";
import api from "./api";
import moment from "moment";
import Swal from "sweetalert2";
import { Tooltip } from "react-tooltip";
import Footer from "./Footer";
const StepSeven = () => {
  const [info, setInfo] = useState([]);
  const [active, setActive] = useState(false);
  const [items, setItems] = useState([]);
  const [slots, setSlots] = useState([
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
  ]);
  const [options, setOptions] = useState({
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    daySuffix: "ordinal",
  });
  const Navigate = useNavigate();

  useEffect(() => {
    getTimeSlots();
  }, []);

  const gaEventTracker = AnalyticsEventTracker("Available Slots");

  const getTimeSlots = async () => {
    const data = sessionStorage.getItem("info");
    if (data) {
      setInfo(JSON.parse(data));
      var session = "";
      if (JSON.parse(data).sessionId) session = JSON.parse(data).sessionId;
      const resp = await api.post("availability.php", { session_id: session });
      const date_str = new Date(JSON.parse(data).date);
      const date_slot = date_str.toISOString().split("T")[0];
      const epi_slot = resp.data.data.find(
        (obj) => Object.keys(obj)[0] === date_slot
      );
      if (epi_slot) setItems(epi_slot[date_slot]);
      else if (JSON.parse(data).order_id) setItems(JSON.parse(data).time_slot);
      setActive(true);
    } else Navigate("/");
  };

  const setpChange = async (value) => {
    if (items.includes(value)) {
      gaEventTracker("Slot Selected");
      info.time_slot = value;
      setInfo({ ...info });
      sessionStorage.setItem("info", JSON.stringify(info));
      if (info.order_id) {
        const resp = await api.post("update_order.php", info);
      } else {
        const resp = await api.post("save_order.php", info);
        if (resp.data.success == true) {
          const order = { ...info };
          order.order_id = resp.data.order_id;
          sessionStorage.setItem("info", JSON.stringify(order));
        }
      }
      Navigate("/card-details");
    } else {
      Swal.fire({
        text: "Sorry, please select another time",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <>
      {active ? (
        <div className="epione">
          <div className="epi_container">
            <Header url="/select-date" />
            <div className="img_container">
              <div className="epi_page_title">Available timeslots on</div>
              <h4> {moment(info.date).format("dddd, MMMM Do")}</h4>
            </div>
            <div>
              <div className="row">
                {slots.map((slot, i) => (
                  <div key={i} className="col-sm-6">
                    <div
                      onClick={() => setpChange(slot)}
                      className={
                        (info.time_slot === slot && items == slot) ||
                        (info.order_id > 0 && info.time_slot == slot)
                          ? "epi_lin epi_lin_active"
                          : items == slot
                          ? "epi_lin epi_enable"
                          : "epi_lin"
                      }
                    >
                      {" "}
                      {slot}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="mt-3 slots_block"
                style={{ display: "flex", paddingTop: 25 }}
              >
                <div
                  className="slots_block_div"
                  style={{ display: "flex", marginRight: 15 }}
                >
                  <span
                    className="epi_ball"
                    data-tooltip-id="a_slots"
                    data-tooltip-content="Slots Available"
                    style={{ backgroundColor: "#1976d2" }}
                  ></span>{" "}
                  <b>Slots Available</b>
                  <Tooltip id="a_slots" />
                </div>
                <div
                  className="slots_block_div"
                  style={{ display: "flex", marginRight: 15 }}
                >
                  <span
                    className="epi_ball"
                    style={{ backgroundColor: "#e0e0e0" }}
                    data-tooltip-id="no_slots"
                    data-tooltip-content="No Slots Available"
                  ></span>{" "}
                  <b>No Slots Available</b>
                </div>
                <Tooltip id="no_slots" />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : null}
    </>
  );
};
export default StepSeven;
