import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DrImg from "./../images/dr_img.png";
import { Link, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import queryString from "query-string";

import AnalyticsEventTracker from "./AnalyticsEventTracker";

const Welcome = () => {
  const { data } = useParams();
  const Navigate = useNavigate();
  const { referal, referal_url } = queryString.parse(window.location.search);

  const gaEventTracker = AnalyticsEventTracker("Get Started");

  const params = [
    "threads",
    "tiktok",
    "facebook",
    "pinterest",
    "linkedin",
    "twitter",
    "instagram-bio",
    "Instagram-channel",
    "instagram-ad-1",
    "Instagram-ad-2",
    "Instagram-ad-3",
    "Instagram/stories",
    "prweb",
    "sms",
    "email",
  ];

  useEffect(() => {
    var info = sessionStorage.getItem("info");
    if (data) {
      var info_data = {};
      if (info) {
        info_data = JSON.parse(info);
        info_data.referal = data;
      } else info_data = { referal: data };
      sessionStorage.setItem("info", JSON.stringify(info_data));
      Navigate("/");
    } else if (referal || referal_url) {
      var info_data = {};
      if (info) {
        info_data = JSON.parse(info);
        info_data.referal = referal;
        info_data.referal_url = referal_url;
      } else info_data = { referal: referal, referal_url: referal_url };
      sessionStorage.setItem("info", JSON.stringify(info_data));
      Navigate("/");
    }
  }, []);
  return (
    <div className="epione">
      <div className="epi_container">
        <Header />
        <div className="img_container">
          <img src={DrImg} alt="Epione" />
        </div>
        <div className="epi_title">
          <h3 className="epi_heading">
            Welcome to <br /> Epione Beverly Hills! <br />
            <br /> Click on the button <br />
            below to schedule <br /> an appointment
          </h3>
        </div>
        <div className="epi_btn_sec">
          <Link
            className="epi_btn"
            to="/about"
            onClick={() => gaEventTracker("call")}
            style={{ textTransform: "none" }}
          >
            Let's get started!
          </Link>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Welcome;
